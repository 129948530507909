import { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import EnFlag from '../../assets/en_flag.svg';
import ItFlag from '../../assets/it_flag.svg';

import Text from "../../shared/lib/widgets/Text";


const LanguageToggleStyle = styled.div`
    user-select: none;
    display: flex;
    background-color: #F6F7F9;
    margin: 2px;
    padding: 2px;
    border-radius: 4px;
    height: 40px;
`;

const LanguageToggleItemStyle = styled.div<{ $isSelected?: boolean }>`
    background-color: ${(props) => props.$isSelected ? '#FFF' : 'transparent'};
    padding: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;

    &:hover {
        background-color: #F2F4F6;
    }
`;

const LanguageToggle = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');

    useEffect(() => {
        setLanguage(localStorage.getItem('i18nextLng') || 'en');
    }, [i18n.language]);

    const handleLanguageChange = (e: any, lang: any) => {
        e.stopPropagation();
        
        i18n.changeLanguage(lang);
        setLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
    };

    return (
        <LanguageToggleStyle>
            <LanguageToggleItemStyle onClick={(e) => handleLanguageChange(e, 'en')} $isSelected={language === 'en'}>
                <img src={EnFlag} alt="en-flag" />

                <Text>EN</Text>
            </LanguageToggleItemStyle>

            <LanguageToggleItemStyle onClick={(e) => handleLanguageChange(e, 'it')} $isSelected={language === 'it'}>
                <img src={ItFlag} alt="it-flag" />

                <Text>IT</Text>
            </LanguageToggleItemStyle>
        </LanguageToggleStyle>
    );
};

export default LanguageToggle;

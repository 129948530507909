import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Teams from '../components/PageContent/Settings/Team';
import ReferAndEarn from '../components/PageContent/Settings/RefferAndEarn';
import ProfileSection from '../components/PageContent/Settings/PersonalInfo';
import SecuritySection from '../components/PageContent/Settings/ChangePassword';
import BillingAndPayments from '../components/PageContent/Settings/BillingAndPayments';

import TabBarButtons from '../components/Buttons/TabBarButtons';

import Text from '../shared/lib/widgets/Text';
import Container from '../shared/lib/widgets/Container';
import RowWidget from '../shared/lib/widgets/RowWidget';


const AccountSettings: React.FC = () => {
    const { t } = useTranslation();

    const [selectedSegment, setSelectedSegment] = useState<number>(0);

    const tabBarItems = [
        t('settings.toggle.personal_info'),
        t('settings.toggle.billing_and_payments'),
        t('settings.toggle.refer_and_earn'),
        t('settings.toggle.team'),
        t('settings.toggle.security'),
    ];

    const renderSelectedSection = () => {
        switch (selectedSegment) {
            case 0:
                return <ProfileSection />;
            case 1:
                return <BillingAndPayments />;
            case 2:
                return <ReferAndEarn />;
            case 3:
                return <Teams />;
            case 4:
                return <SecuritySection />;
            default:
                return <ProfileSection />;
        }
    };

    return (
        <Container $backgroundColor='#F9FAFC' $padding='32px 0' style={{ minHeight: '92vh' }}>
            <RowWidget $justifyContent='center'>
                <Container $backgroundColor='#FFF' $padding='16px 32px' $borderRadius='12px' $width='1135px'>
                    <Text fontSize='20px' color='#201F29' fontWeight='bold'>{t('settings.title')}</Text>

                    <Container $height='10px' />

                    <Text fontSize='16px' color='#908F94'>{t('settings.subtitle')}</Text>

                    <Container $height='32px' />

                    <TabBarButtons 
                        onClick={setSelectedSegment}
                        value={selectedSegment}
                        items={tabBarItems}
                    />

                    <Container $height='32px' />

                    {renderSelectedSection()}
                </Container>
            </RowWidget>
        </Container>
    );
};

export default AccountSettings;

import React from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ShareIcon from '../../assets/share.svg';
import CloudIcon from '../../assets/cloud.svg';
import PrintIcon from '../../assets/printer.svg';
import DeleteIcon from '../../assets/delete.svg';

import PrimaryButton from "../../components/Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


const DocumentHeader = styled.div`
    position: sticky;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #FFF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 16px;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #E8EBF0;
    padding: 16px;
`;

const DocumentHeaderTitle = styled.div`
    white-space: nowrap;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1145px) {
        width: 120px;
    }
    @media (max-width: 1024px) {
        display: none;
    }
`;

const DocumentHeaderBack = styled.div`
    padding: 8px;
    height: 100%;
    background-color: #E8EBF0;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const DocumentExportStyle = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

interface ToolsProps {
    editorState: any;
    setEditorState: any;
}

const EditorHeader: React.FC<ToolsProps> = ({ editorState, setEditorState }) => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <DocumentHeader>
            <RowWidget $alignItems="center" $gap="12px" $height="100%">
                <DocumentHeaderBack onClick={() => navigate(-1)}>{'<'}</DocumentHeaderBack>

                <DocumentHeaderTitle>
                    <Text fontSize="20px" fontWeight="bold" overflow='hidden' $textOverflow='ellipsis'>Johnson vs. Tech Innovations Corp.</Text>

                    <RowWidget $gap="8px">
                        <Text color="#8E9092" fontSize="14px" overflow='hidden' $textOverflow='ellipsis'>Created 27 Feb, 2024</Text>

                        <Container $width="1px" $height="100%" $backgroundColor="#8E9092" />

                        <Text color="#8E9092" fontSize="14px" overflow='hidden' $textOverflow='ellipsis'>Jeffry Jhones</Text>
                    </RowWidget>
                </DocumentHeaderTitle>
            </RowWidget>

            {/* <Tools editorState={editorState} setEditorState={setEditorState} /> */}
            <Container />

            <RowWidget $justifyContent="flex-end" $alignItems="center" $gap="12px">
                <PrimaryButton 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    title='Save'
                    $width="120px"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={ShareIcon}
                    alt="icon"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={CloudIcon}
                    alt="icon"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={PrintIcon}
                    alt="icon"
                />
                <DocumentExportStyle 
                    onClick={() => toast.info(t('messages.info.service_not_available'))}
                    src={DeleteIcon}
                    alt="icon"
                />
            </RowWidget>
        </DocumentHeader>
    );
}


export default EditorHeader;
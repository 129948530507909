import React, { useState } from "react";

import styled from "styled-components";

import InfoIcon from '../../assets/info_icon.svg';
// import FileIcon from '../../assets/file_icon.svg';
// import ChatIcon from '../../assets/chat_comment.svg';
// import AiDocumentIcon from '../../assets/chat_setting_ai_document.svg';

import Input from "../Inputs/Input";
import InputPhone from "../Inputs/InputPhone";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import PrimaryButton from "../Buttons/PrimaryButton";


const ChatSettingsSidebarStyle = styled.div`
    background-color: #FFF;
    border-right: 1px solid #E4E4E4;
    width: 300px;
    min-width: 300px;
    padding: 12px;
    position: sticky;
    top: 150px;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: calc(100vh - 150px);
`;

const ChatSettingsSidebarItem = styled.div<{ $active?: boolean }>`
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background-color: ${(props) => props.$active ? '#E8EBF0' : '#fff'};
    padding: 12px;
    cursor: pointer;
    &:hover {
        background-color: #eee;
    }
`;

interface ChatSettingsSidebarProps {
    onClose: () => void;
}

const ChatSettingsSidebar: React.FC<ChatSettingsSidebarProps> = ({ onClose }) => {
    const [selectedSegment, setSelectedSegment] = useState<number>(0);

    const renderSelectedSection = () => {
        switch (selectedSegment) {
            case 0:
                return <CaseDetails />;
            default:
                return <CaseDetails />;
        }
    };
    
    const menuItems = [
        // {
        //     icon: ChatIcon,
        //     title: 'Chat'
        // },
        {
            icon: InfoIcon,
            title: 'Case Details'
        },
        // {
        //     icon: FileIcon,
        //     title: 'Documents (by lawyer)'
        // },
        // {
        //     icon: AiDocumentIcon,
        //     title: 'Documents (by AI)'
        // },
    ];

    return (
        <RowWidget $width="100%">
            <ChatSettingsSidebarStyle>
                <ChatSettingsSidebarItem onClick={onClose}>
                    <Text color="#153968" fontWeight="bold">{"<"}</Text>

                    <Text color="#153968" fontWeight="bold">Back to chat</Text>
                </ChatSettingsSidebarItem>

                <div style={{ borderBottom: '1px solid #E4E4E4', margin: '6px 0' }} />

                {
                    menuItems.map((item: any, index: number) =>
                        <ChatSettingsSidebarItem key={index} onClick={() => setSelectedSegment(index)} $active={selectedSegment === index}>
                            <img src={item.icon} alt="icon" />
            
                            <Text color="#153968" fontWeight="bold">{item.title}</Text>
                        </ChatSettingsSidebarItem>
                    )
                }
            </ChatSettingsSidebarStyle>

            {renderSelectedSection()}
        </RowWidget>
    );
}


export default ChatSettingsSidebar;



const CaseDetails = () => {
    return (
        <Container $backgroundColor="#F9FAFC" $padding="30px" $width="100%">
            <Text fontWeight='bold' fontSize='20px'>Case Details</Text>

            <Container $height='10px' />

            <Text color='#908F94'>Share additional information that will better characterize the case and provide the language model with more detailed contextual elements.</Text>

            <Container $height='32px' />

            <CaseDetailsType />
            <CaseDetailsIdentification />
            <CaseDetailsActivity />

            <RowWidget $justifyContent="flex-end">
                <PrimaryButton 
                    $width="220px"
                    onClick={() => {}}
                    title="Save"
                    $isDisabled
                />
            </RowWidget>
        </Container>
    );
}

const CaseDetailsType = () => {
    return (
        <Container $backgroundColor="#FFF" $padding="30px" $borderRadius="12px" $margin="0 0 32px 0" $cursor="pointer">
            <RowWidget $gap="15%">
                <Container $width="25%" $height="fit-content">
                    <Text fontSize='18px' fontWeight='bold'>1. Case type</Text>

                    <Container $height="12px" />

                    <Text color='#908F94'>Specify the type of case being handled, choose between a Judicial case, which involves court proceedings, or an Extrajudicial case, which includes activities such as mediation, legal consultation, or contract drafting.</Text>
                </Container>

                <Container>
                    <Text fontWeight='bold' color="#7E7D83">SELECT CASE TYPE</Text>

                    <Container $height="16px" />

                    <RowWidget $gap="6px" $alignItems="center">
                        <input 
                            type="radio" 
                            name="case_type" 
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                        />
                        <Text>Judical</Text>
                    </RowWidget>

                    <Container $height="12px" />

                    <RowWidget $gap="6px" $alignItems="center">
                        <input 
                            type="radio" 
                            name="case_type" 
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                        />
                        <Text>Extrajudical</Text>
                    </RowWidget>
                </Container>
            </RowWidget>
        </Container>
    );
}

const CaseDetailsIdentification = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <Container onClick={() => setIsOpen(!isOpen)} $backgroundColor={isOpen ? "#FFF" : "#E9E9EA"} $padding="30px" $borderRadius="12px" $margin="0 0 32px 0" $cursor="pointer">
            <RowWidget $gap="15%">
                <Container $width="25%" $height="fit-content">
                    <Text fontSize='18px' fontWeight='bold'>2. Identification of the arties</Text>

                    <Container $height="12px" />

                    <Text color='#908F94'>Identify the parties involved in the case. Depending on whether the party is a Natural Person or a Legal Entity, the form will request relevant details, such as personal information for individuals or legal documentation for companies.</Text>
                </Container>

                {
                    isOpen &&
                        <Container $width="calc(100% - 25% - 15%)">
                            <RowWidget $gap="16px">
                                <Input 
                                    label="Party"
                                />

                                <Input 
                                    label="Type of Party"
                                />
                            </RowWidget>

                            <Container $height="12px" />

                            <Text fontWeight="bold">Natural Person Information</Text>

                            <Container $height="10px" />

                            <RowWidget $gap="16px">
                                <Input 
                                    label="First Name"
                                />

                                <Input 
                                    label="Last Name"
                                />
                            </RowWidget>
                            
                            <RowWidget $gap="16px">
                                <Input 
                                    label="Email"
                                />

                                <InputPhone 
                                    value=""
                                    onChange={() => {}}
                                />
                            </RowWidget>
                            
                            <Input 
                                label="Address"
                            />

                            <Input 
                                label="Tax code"
                            />

                            <Container $height="12px" />

                            <Text fontWeight="bold">Attorney Information</Text>

                            <Container $height="10px" />

                            <RowWidget $gap="16px">
                                <Input 
                                    label="First Name"
                                />

                                <Input 
                                    label="Last Name"
                                />
                            </RowWidget>

                            <RowWidget $gap="16px">
                                <Input 
                                    label="Email"
                                />

                                <InputPhone 
                                    value=""
                                    onChange={() => {}}
                                />
                            </RowWidget>

                            <RowWidget $gap="16px">
                                <Input 
                                    label="Bar registration number"
                                />

                                <Input 
                                    label="Tax code"
                                />
                            </RowWidget>

                            <Input 
                                label="Law Firm Name (if applicable)"
                            />
                        </Container>
                }
            </RowWidget>
        </Container>
    );
}

const CaseDetailsActivity = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <Container onClick={() => setIsOpen(!isOpen)} $backgroundColor={isOpen ? "#FFF" : "#E9E9EA"} $padding="30px" $borderRadius="12px" $margin="0 0 32px 0" $cursor="pointer">
            <RowWidget $gap="15%">
                <Container $width="25%">
                    <Text fontSize='18px' fontWeight='bold'>3. Type of case or activity</Text>

                    <Container $height="12px" />

                    <Text color='#908F94'>Based on your selection in the Case Type section, this part will gather specific details about the case.</Text>
                </Container>

                {
                    isOpen &&
                        <Text>Currently empty</Text>
                }
            </RowWidget>
        </Container>
    );
}
import React from 'react';

import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '../../assets/SearchIcon.svg'
import SettingsIcon from '../../assets/SettingIcon.svg'
import NotificationsIcon from '../../assets/notification.svg'

import TextButton from '../Buttons/TextButton';

import Text from '../../shared/lib/widgets/Text';
import RowWidget from '../../shared/lib/widgets/RowWidget';
import Container from '../../shared/lib/widgets/Container';
import { useAppSelector } from '../../shared/components/useAppSelector/useAppSelector';
import { StateSchema } from '../../app/providers/StoreProvider/config/StateSchema';


const ChatSubHeaderStyle = styled.div`
    position: sticky;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #FFF;
    width: 100%;
    height: 80px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E8EBF0;
`;

const ChatSubHeaderBackStyle = styled.div`
    padding: 8px 12px;
    height: 100%;
    background-color: #E8EBF0;
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const ChatSubHeaderTitle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 524px) {
        display: none;
    }
`;

const SearchInChatStyle = styled.div`
    border: 1px solid #E9E9EA;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    border-radius: 8px;
    overflow: hidden;
    height: 40px;
    width: 320px;
    &:focus-within {
        outline: 1px solid #4158E3;
    }
    @media (max-width: 876px) {
        display: none;
    }
`;

const NotificationContainerStyle = styled.div`
    position: relative;
    cursor: pointer;
    height: 24px;
`;

const NotificationDotStyle = styled.div`
    position: absolute;
    top: 0;
    right: 2px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    border: 1px solid #FFF;
`;


interface SubHeaderProps {
    title: string;
    id: string;
    showCaseSettings: () => void;
}

const SubHeader: React.FC<SubHeaderProps> = ({ title, id, showCaseSettings }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const user = useAppSelector((state: StateSchema) => state.user.userData);

    return (
        <ChatSubHeaderStyle>
            <RowWidget $alignItems='center' $gap='12px' $height="100%">
                <ChatSubHeaderBackStyle onClick={() => navigate('/')}>{'←'}</ChatSubHeaderBackStyle>

                <ChatSubHeaderTitle>
                    <RowWidget $gap='8px'>
                        <Text fontSize='20px' fontWeight='bold'>{title}</Text>

                        <TextButton 
                            onClick={() => {
                                navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/case/${id}`);
                                toast.success(t('messages.success.link_copied'));
                            }}
                            title={`#${id}`}
                            color='#007FFF'
                            fontSize='20px'
                            fontWeight='bold'
                        />
                    </RowWidget>

                    <RowWidget $gap='4px'>
                        <Text color='#8E9092'>{t('chat.subheader.lawyer')}</Text>
                        <Text>-</Text>

                        <Container $width="1px" $height="100%" $backgroundColor="#8E9092" />

                        <Text color='#8E9092'>{t('chat.subheader.client')}</Text>
                        <Text>{user?.first_name} {user?.last_name}</Text>
                    </RowWidget>
                </ChatSubHeaderTitle>
            </RowWidget>

            <RowWidget $alignItems='center' $gap='12px'>
                <SearchInChatStyle>
                    <img 
                        src={SearchIcon} 
                        alt="icon" 
                        width='20px' 
                    />

                    <input 
                        type="text" 
                        placeholder={t('input.search_placeholder')}
                        style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </SearchInChatStyle>

                <NotificationContainerStyle>
                    <img 
                        onClick={() => toast.info(t('messages.info.service_not_available'))}
                        src={NotificationsIcon} 
                        alt="icon" 
                        style={{
                            cursor: 'pointer',
                            height: '100%',
                        }}
                    />

                    <NotificationDotStyle />
                </NotificationContainerStyle>

                <img 
                    onClick={showCaseSettings}
                    src={SettingsIcon} 
                    alt="icon" 
                    style={{
                        cursor: 'pointer',
                        height: '24px',
                    }}
                />
            </RowWidget>
        </ChatSubHeaderStyle>
    );
}

export default SubHeader;